import { Fragment, type FC } from 'react'
import classNames from 'classnames'
import { isUndefined } from 'lodash'

import { Text } from 'components'
import { ArrowRightIcon } from 'assets'

import { TContentIndexItemProps } from './types'
import styles from './ContentIndex.module.scss'

const ContentIndexItem: FC<TContentIndexItemProps> = ({
  onClick,
  itemClassName,
  variant,
  text,
  id,
  tabIndex,
  isSubmitted,
}) => {
  const orderActiveClasses = {
    [styles.active]: tabIndex === id,
    [styles.isActive]: (!isUndefined(tabIndex) && tabIndex - 1 >= id) || isSubmitted,
  }

  const renderContent = () => {
    switch (variant) {
      case 'order':
        return (
          <div role='button' onClick={() => onClick(id)} className={classNames(itemClassName, styles.wrapper__item)}>
            <div className={classNames(styles.wrapper__item__number, orderActiveClasses)}>{id}</div>

            <div className={styles.wrapper__item__container}>
              <Text className={styles.wrapper__item__container__text} text={text} />

              <div className={classNames(styles.wrapper__item__container__line, orderActiveClasses)} />
            </div>
          </div>
        )
      case 'button':
        return (
          <div
            role='button'
            onClick={() => onClick(id)}
            className={classNames(itemClassName, styles.button, { [styles.button__active]: tabIndex === id })}
          >
            <Text text={text} className={styles.button__text} />

            <ArrowRightIcon />
          </div>
        )
      default:
        return <Fragment />
    }
  }

  return renderContent()
}

export default ContentIndexItem
