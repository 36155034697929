import type { FC } from 'react'

import { EProgram } from 'types'
import { Button, Text } from 'components'
import { programInfoIconMapper } from 'utils'
import { EButtonVariants, ESizes } from 'components/types'

import type { TLessonHeaderProps } from './types'
import styles from './LessonHeader.module.scss'

const LessonHeader: FC<TLessonHeaderProps> = ({
  onSave,
  onCancel,
  onPublish,
  isRestrictPublish = false,
  degree = EProgram.Bachelor,
}) => {
  const Icon = programInfoIconMapper[degree as keyof typeof programInfoIconMapper]

  return (
    <div className={styles.wrapper}>
      <div className={styles.wrapper__btn}>
        <Button
          size={ESizes.Large}
          onClick={onCancel}
          variant={EButtonVariants.Tertiary}
          className={styles.wrapper__btn__cancel}
        >
          Cancel
        </Button>
      </div>
      <div className={styles.wrapper__center}>
        <Icon />
        <Text text={degree} tagName='p' className={styles.wrapper__center__degree} />
      </div>
      <div className={styles.wrapper__buttons}>
        <Button size={ESizes.Large} variant={EButtonVariants.Secondary} onClick={onSave}>
          Save
        </Button>
        <Button size={ESizes.Large} variant={EButtonVariants.Primary} disabled={isRestrictPublish} onClick={onPublish}>
          Publish
        </Button>
      </div>
    </div>
  )
}

export default LessonHeader
