import type { FC } from 'react'

import { Button } from 'components'
import { DeleteIcon, EditIcon } from 'assets'
import { EButtonVariants, ESizes } from 'components/types'

import Answer from './Answer'
import type { IExamQuestion } from './types'
import styles from './LessonExam.module.scss'

const LessonExam: FC<IExamQuestion> = ({ question, answers, order, onEdit, onDelete }) => (
  <div className={styles.wrapper}>
    <div className={styles.wrapper__order}>
      <span className={styles.wrapper__order__number}>{order}</span>
    </div>
    <div className={styles.wrapper__container}>
      <p className={styles.wrapper__container__question}>Question</p>
      <p className={styles.wrapper__container__text}>{question}</p>
      <div className={styles.wrapper__container__answers}>
        <p className={styles.wrapper__container__answers__answer}>Answer</p>
        {answers.map((element, index) => (
          <Answer key={index} answer={element.answer} isCorrect={element.isCorrect} />
        ))}
      </div>
      <div className={styles.wrapper__container__footer}>
        <div className={styles.wrapper__container__footer__edit}>
          <Button onClick={onEdit} size={ESizes.Large} variant={EButtonVariants.Secondary} LeftIcon={EditIcon}>
            Edit
          </Button>
        </div>
        <div className={styles.wrapper__container__footer__delete}>
          <Button onClick={onDelete} size={ESizes.Large} variant={EButtonVariants.Tertiary} RightIcon={DeleteIcon} />
        </div>
      </div>
    </div>
  </div>
)

export default LessonExam
