import { ReactNode } from 'react'
import classNames from 'classnames'

import LeftBanner from './LeftBanner'
import styles from './AuthLayout.module.scss'

type Props = {
  children: ReactNode
}

const AuthLayout = ({ children }: Props) => (
  <div className={styles.wrapper}>
    <LeftBanner />

    <div className={classNames(styles.wrapper__right)}>{children}</div>
  </div>
)

export default AuthLayout
