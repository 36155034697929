import type { FC } from 'react'
import classNames from 'classnames'

import { Button } from 'components'
import { MinimizeIcon } from 'assets'
import { ESizes, EButtonVariants } from 'components/types'

import type { TSwitchOpenSet } from '../types'
import styles from './SidebarSwitch.module.scss'

const SidebarSwitch: FC<TSwitchOpenSet> = ({ open, setOpen }) => {
  const switchClassName = classNames(styles.wrapper, {
    [styles.wrapper__active]: !open,
  })

  const onSidebarSwitchCallback = () => {
    setOpen(!open)
  }

  return (
    <div className={switchClassName}>
      <Button
        size={ESizes.Small}
        RightIcon={MinimizeIcon}
        onClick={onSidebarSwitchCallback}
        variant={EButtonVariants.Tertiary}
        className={styles.wrapper__button}
      />
    </div>
  )
}

export default SidebarSwitch
