import type { FC } from 'react'
import classNames from 'classnames'
import { NavLink } from 'react-router-dom'

import { Link, Text, Checkbox } from 'components'
import { ESizes, EPlacementVariants } from 'types'

import type { ICheckBoxPrivacy } from './types'
import styles from './CheckboxPrivacyConditions.module.scss'

const CheckboxPrivacyConditions: FC<ICheckBoxPrivacy> = ({ name, register, className, checked, setChecked }) => (
  <div className={classNames(styles.checkbox, className)}>
    <Checkbox
      topMargin
      name={name}
      checked={checked}
      register={register}
      onChange={setChecked}
      labelClassName={styles.checkbox__label}
      labelPlacement={EPlacementVariants.Right}
      labelContainerClass={styles.label_container}
      checkboxClassName={styles.checkbox__label_checkbox}
      label={
        <div role='button' className={styles.checkbox__conditions}>
          <Text emptyTag text='AcceptOur' />{' '}
          <NavLink target='_blank ' to='' className={styles.checkbox__conditions__links}>
            {' '}
            <Text emptyTag text='PrivacyPolicy' />{' '}
          </NavLink>
          <Text emptyTag text='And' />
          <Link navigateTo='' size={ESizes.Medium}>
            Terms&Conditions
          </Link>
        </div>
      }
    />
  </div>
)

export default CheckboxPrivacyConditions
