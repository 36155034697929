import type { RootState } from 'types'

const rolePermissionSelector = (state: RootState) => state.invite.rolePermission
const adminInvitationSelector = (state: RootState) => state.invite.adminInvitation
const adminWorkersGetSelector = (state: RootState) => state.invite.adminWorkersGet
const createAdminReqPostSelector = (state: RootState) => state.invite.createAdminReq
const checkInviteUserTokenSelector = (state: RootState) => state.invite.checkInvitedUserToken

export const InvitationSelector = {
  rolePermissionSelector,
  adminInvitationSelector,
  adminWorkersGetSelector,
  createAdminReqPostSelector,
  checkInviteUserTokenSelector,
}
