import type { FC } from 'react'
import classNames from 'classnames'

import { CloseIcon, TickSecondaryIcon } from 'assets'

import type { TAnswerProps } from './types'
import styles from './Answer.module.scss'

const Answer: FC<TAnswerProps> = ({ answer, isCorrect: isTrue }) => (
  <div className={styles.wrapper}>
    <p className={styles.wrapper__answer}>{answer}</p>
    <div className={classNames(styles.wrapper__icon, { [styles.wrapper__close]: !isTrue })}>
      {isTrue ? <TickSecondaryIcon /> : <CloseIcon />}
    </div>
  </div>
)

export default Answer
