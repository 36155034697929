import { EProgram } from 'types'
import { ICreateLessonProps } from '../CreateLesson/types'

export const createLessonData: ICreateLessonProps[] = [
  {
    navigateTo: '/',
    lessonVersion: EProgram.FreeLesson,
  },
  {
    navigateTo: `${EProgram.Bachelor}/create-course`,
    lessonVersion: EProgram.Bachelor,
  },
  {
    navigateTo: '/',
    lessonVersion: EProgram.Expert,
  },
  {
    navigateTo: '/',
    lessonVersion: EProgram.Master,
  },
  {
    navigateTo: '/',
    lessonVersion: EProgram.Professor,
  },
]
