import { TopicIcon, VideoIcon } from 'assets'
import { ETopics } from 'components/types'

export const TopicsIconMapper = {
  [ETopics.Topics]: TopicIcon,
  [ETopics.Lessons]: VideoIcon,
}

export const TopicsTextMapper = {
  [ETopics.Topics]: 'Topics',
  [ETopics.Lessons]: 'Lessons',
}
