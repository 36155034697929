import { FC, useRef, useState } from 'react'
import classNames from 'classnames'

import { GloboseIcon } from 'assets'
import { useLanguageTranslation } from 'hooks'
import useOutsideClick from 'hooks/useOnClickOutside'

import { Text } from 'components'

import { languagesDropdownUtils } from './utils'
import styles from './LangDropdown.module.scss'

const LangDropdown: FC = () => {
  const ref = useRef<HTMLDivElement>(null)

  const [dropActive, setDropActive] = useState<boolean>(false)

  const { onLanguageChangeCallback, isEnglish } = useLanguageTranslation()

  const onOutsideClickCallback = () => setDropActive(false)

  const onDropdownClickCallback = () => setDropActive(!dropActive)

  const onLanguageClickCallback = (lang: string) => () => {
    setDropActive(false)
    onLanguageChangeCallback(lang)
  }

  useOutsideClick(ref, onOutsideClickCallback)

  const pickedLanguage: string = isEnglish ? 'ENG' : 'RUS'

  const droppedItemWrapperClassName = classNames(styles.wrapper__dropdown, {
    [styles.wrapper__dropped]: dropActive,
  })

  const langItemClassName = (styleCondition: boolean) => {
    return classNames(styles.wrapper__dropdown__item, {
      [styles.wrapper__active__lang]: styleCondition,
    })
  }

  const renderLanguages = languagesDropdownUtils(isEnglish).map(({ text, lang, styleCondition }, idx) => (
    <div key={idx} onClick={onLanguageClickCallback(lang)} role='button'>
      <Text tagName='p' className={langItemClassName(styleCondition)} text={text} />
    </div>
  ))

  return (
    <div className={styles.wrapper} ref={ref}>
      <div className={styles.wrapper__text} onClick={onDropdownClickCallback}>
        <GloboseIcon />
        <Text text={pickedLanguage} tagName='span' />
      </div>
      <div className={droppedItemWrapperClassName}>{renderLanguages}</div>
    </div>
  )
}

export default LangDropdown
