export enum EPlacementVariants {
  Right = 'right',
  Left = 'left',
  Top = 'top',
  Bottom = 'bottom',
}

export enum EPlacementCourseCardAvatar {
  Left = 'left',
  Right = 'right',
}

export enum EButtonVariants {
  Primary = 'primary',
  Secondary = 'secondary',
  Tertiary = 'tertiary',
}

export enum ESizes {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}

export type TResults = {
  wrongAnswers: number
  correctAnswers: number
}

export type TModalProps = {
  openModal: boolean
  handleClose: () => void
}

export enum EQuestionType {
  Checkbox = 'checkbox',
  Radio = 'radio',
  TextArea = 'textarea',
}

export enum ETopics {
  Topics = 'Topics',
  Lessons = 'Lessons',
}

export enum EInput {
  TextType = 'text',
  EmailType = 'email',
  PasswordType = 'password',
}

export type TRolePermissions = {
  id: number
  name: string
  selected?: boolean
}
