import type { FC } from 'react'
import { noop } from 'lodash'
import classNames from 'classnames'

import Checkbox from '../Checkbox'

import type { TRoleItemProps } from './types'
import styles from './RoleItem.module.scss'

const RoleItem: FC<TRoleItemProps> = ({ changeHandler = noop, permissions, title, className = '' }) => {
  const isPermissions = !!permissions?.length

  const renderPermissions = permissions?.map(item => {
    const { name, selected, id } = item

    return (
      <Checkbox
        key={id}
        checked={selected}
        label={name.replace(/\_/g, ' ')}
        className={styles.wrapper__bottom__width}
        labelContainerClass={styles.wrapper__bottom__item}
        onChange={(itm: boolean) => changeHandler(itm, item)}
      />
    )
  })

  return (
    <div className={classNames(styles.wrapper, { [className]: className })}>
      <div
        className={classNames(styles.wrapper__header, {
          [styles.wrapper__header__empty_per]: !isPermissions,
        })}
      >
        <p className={styles.wrapper__header__text}>{title}</p>

        {!isPermissions && <Checkbox className={styles.wrapper__header__checkbox} onChange={changeHandler} />}
      </div>

      {isPermissions && <div className={styles.wrapper__bottom}>{renderPermissions}</div>}
    </div>
  )
}

export default RoleItem
