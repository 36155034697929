import type { FC } from 'react'
import classNames from 'classnames'

import type { IContentIndex } from './types'
import ContentIndexItem from './ContentIndexItem'
import styles from './ContentIndex.module.scss'

const ContentIndex: FC<IContentIndex> = ({
  items,
  tabIndex,
  isSubmitted,
  setTabIndex,
  variant = 'order',

  // disabled = 'false',
  itemClassName = '',
  containerClassName = '',
}) => {
  const handleContentNavigation = (id: number) => {
    setTabIndex(id)
  }

  const renderItems = items?.map(({ id, text }) => (
    <ContentIndexItem
      id={id}
      key={id}
      text={text}
      tabIndex={tabIndex}
      variant={variant}
      isSubmitted={isSubmitted}
      itemClassName={itemClassName}
      onClick={() => handleContentNavigation(id)}
    />
  ))

  return <div className={classNames(styles.wrapper, containerClassName)}>{renderItems}</div>
}

export default ContentIndex
