import type { FC } from 'react'
import classNames from 'classnames'

import type { TCardProps } from './types'
import styles from './Card.module.scss'

const Card: FC<TCardProps> = ({ text, draggable, className = '' }) => (
  <div draggable={draggable} className={classNames(styles.wrapper, { [className]: className })}>
    {text}
  </div>
)

export default Card
