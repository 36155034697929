import { createAsyncThunk } from '@reduxjs/toolkit'

import axiosInstance from 'libraries/axios'
import { ToastVersions, showNotification } from 'libraries/toastify'
import type { TSubmitBody } from 'components/molecules/WorkerInvitationModal/types'

export const adminInvitation = createAsyncThunk('admin/invitations', async (_, { rejectWithValue }) => {
  try {
    const { data } = await axiosInstance.get('/admin-invitations')

    return data.data
  } catch (error: any) {
    return rejectWithValue(error.response.data)
  }
})

export const createAdminReq = createAsyncThunk(
  'admin/invitations/post',
  async (body: TSubmitBody, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.post('/admin-invitations', body)

      showNotification(ToastVersions.success, data.message)

      return data.data
    } catch (error: any) {
      showNotification(ToastVersions.error, error.response.data.error)

      return rejectWithValue(error.response.data)
    }
  }
)

export const checkInvitedUserToken = createAsyncThunk(
  'check/admin/invitations',
  async (inviteToken: string, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.post('/invitations/check', { inviteToken })

      return data.data
    } catch (error: any) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const rolePermission = createAsyncThunk('role/permissions', async (_, { rejectWithValue }) => {
  try {
    const { data } = await axiosInstance.get('/admin-invitations/role-permissions')

    return data.data
  } catch (error: any) {
    return rejectWithValue(error.response.data)
  }
})

export const adminWorkersGet = createAsyncThunk('admin-workers', async (_, { rejectWithValue }) => {
  try {
    const { data } = await axiosInstance.get('/admin-workers')

    return data.data
  } catch (error: any) {
    return rejectWithValue(error.response.data)
  }
})
