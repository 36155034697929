import { type FC, useEffect, useState } from 'react'
import classNames from 'classnames'
import { Tooltip } from '@mui/material'

import { Text } from 'components'

import type { TTooltipProps } from './type'
import styles from './Tooltip.module.scss'

const TooltipComponent: FC<TTooltipProps> = ({
  label,
  title,
  Icon,
  isError,
  isLight,
  className,
  dataTestId,
  arrow = true,
  placement = 'top',
  titleClassName = '',
  isShowFromStart = false,
  isTooltipTranslationIgnore = false,
  ...restProps
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const isLightBackground = isLight ? '#BDD5F0' : '#0F2640'
  const backgroundColor = isError ? '#fbd4d4' : isLightBackground

  useEffect(() => {
    if (isShowFromStart) {
      setIsOpen(true)

      setTimeout(() => {
        setIsOpen(false)
      }, 2300)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onMouseEnterHandler = () => {
    setIsOpen(true)
  }

  const onMouseLeaveHandler = () => {
    setIsOpen(false)
  }

  return (
    <Tooltip
      open={isOpen}
      arrow={arrow}
      placement={placement}
      className={className}
      data-testid={dataTestId}
      onMouseLeave={onMouseLeaveHandler}
      onMouseEnter={onMouseEnterHandler}
      title={
        isTooltipTranslationIgnore ? (
          title
        ) : (
          <Text
            text={title}
            className={classNames(styles.dark__text, {
              [styles.light__text]: isLight,
              [styles.error__text]: isError,
              [titleClassName]: titleClassName,
            })}
          />
        )
      }
      classes={{ tooltip: isError ? styles.wrapper__error__title : styles.wrapper__title }}
      componentsProps={{
        tooltip: {
          sx: {
            backgroundColor: backgroundColor,
            padding: '8px 12px',

            '& .MuiTooltip-arrow': {
              color: backgroundColor,
            },
          },
        },
      }}
      {...restProps}
    >
      <div className={classNames(styles.wrapper, { [styles.wrapper__error]: isError })}>
        {Icon ? <Icon /> : null}

        {label ? (
          <p className={classNames(styles.wrapper__text, { [styles.wrapper__error__text]: isError })}>{label} </p>
        ) : null}
      </div>
    </Tooltip>
  )
}

export default TooltipComponent
