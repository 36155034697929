import { Select as MUISelect, styled } from '@mui/material'

const SelectStyled = styled(MUISelect)<any>(({ height, padding }) => ({
  width: '100%',
  borderRadius: '8px !important',

  '.MuiSelect-select': {
    width: '100%',
    height: `${height} !important`,
    padding: `${padding} !important`,
    display: 'flex',
    alignItems: 'center',
  },

  '.MuiSelect-icon': {
    width: '20px',
    height: '20px',
    transition: '0.3s all',
  },

  '&.Mui-focused .MuiSelect-icon path': {
    fill: '#0095fa',
  },

  '.MuiOutlinedInput-input': {
    width: '100%',
    outline: 'none',
    fontWeight: '300',
    fontSize: '16px',
    lineHeight: '24px',
    borderRadius: '8px',
    transition: '0.3s all',
    boxSizing: ' border-box',
    backgroundColor: '#142837',
    border: '1px solid transparent',
    color: ' #e6f4ff',
  },

  fieldset: {
    border: 'none !important',
  },

  '.MuiOutlinedInput-input:hover': {
    borderColor: '#6f7d8c',
  },

  '.MuiOutlinedInput-input:focus': {
    borderRadius: '8px !important',
  },

  '.Mui-focused': {
    borderRadius: '8px !important',
    borderColor: '#0068af',
    backgroundColor: '#0f1e29',
  },
}))

export default SelectStyled
