import type { FC } from 'react'

import { Button, Input } from 'components'
import { EButtonVariants, ESizes } from 'components/types'
import { DeleteIcon, TickPrimaryIcon, TickSecondaryIcon } from 'assets'

import type { TAnswerProps } from './types'
import styles from './Answer.module.scss'
import classNames from 'classnames'

const Answer: FC<TAnswerProps> = ({ onAnswer, isCorrect, onDelete, register, error, answerName }) => (
  <div className={styles.wrapper}>
    <Input
      error={error}
      name={answerName}
      register={register}
      size={ESizes.Large}
      placeholder='Answer'
      value={answerName}
    />
    <div className={styles.wrapper__btns}>
      <Button
        isFillIgnore
        onClick={onAnswer}
        size={ESizes.Large}
        variant={EButtonVariants.Secondary}
        className={classNames(styles.wrapper__btns__not_correct, { [styles.wrapper__btns__is_correct]: isCorrect })}
        LeftIcon={isCorrect ? TickSecondaryIcon : TickPrimaryIcon}
      />
      <Button size={ESizes.Large} variant={EButtonVariants.Tertiary} onClick={onDelete} LeftIcon={DeleteIcon} />
    </div>
  </div>
)

export default Answer
