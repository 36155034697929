import { createAsyncThunk } from '@reduxjs/toolkit'
import { isArray } from 'lodash'

import axiosInstance from 'libraries/axios'
import { getCookie, setCookie } from 'libraries/cookie'
import { showNotification, ToastVersions } from 'libraries/toastify'
import { COOKIE_EXPIRATION_TIME, REFRESH_COOKIE_EXPIRATION_TIME } from 'utils'

import type { SignUpBody } from './types'

export const signIn = createAsyncThunk(
  'auth-worker/login',
  async (body: { email: string; password: string }, { rejectWithValue }) => {
    try {
      const rememberMe = getCookie('rememberMe') === '1'

      const { data } = await axiosInstance.post('/auth-worker/login', body)

      setCookie('token', data.data.token, rememberMe ? COOKIE_EXPIRATION_TIME : undefined)
      setCookie('worker', JSON.stringify(data.data.worker), rememberMe ? REFRESH_COOKIE_EXPIRATION_TIME : undefined)
      setCookie('refreshToken', data.data.refreshToken, rememberMe ? REFRESH_COOKIE_EXPIRATION_TIME : undefined)

      showNotification(ToastVersions.success, data.message)

      return data.data
    } catch (error: any) {
      if (isArray(error.response.data.message)) {
        const message = error.response.data.message[0].email[0]

        showNotification(ToastVersions.error, message)
      } else {
        showNotification(ToastVersions.error, error.response.data.message)
      }

      return rejectWithValue(error.response.data)
    }
  }
)

export const signUp = createAsyncThunk('auth/sign-up', async (body: SignUpBody, { rejectWithValue }) => {
  try {
    const data = await axiosInstance.post('/auth-worker/invite-register', body)

    return data
  } catch (error: any) {
    return rejectWithValue(error.response.data)
  }
})

export const forgotPassword = createAsyncThunk('auth/forgotPassword', async (body: any, { rejectWithValue }) => {
  try {
    const { data } = await axiosInstance.post('/auth-worker/forgot-password', body)

    showNotification(ToastVersions.success, data.message)

    return body
  } catch (error: any) {
    if (isArray(error.response.data.message)) {
      const message = error.response.data.message[0].email[0]

      showNotification(ToastVersions.error, message)
    } else {
      showNotification(ToastVersions.error, error.response.data.message)
    }

    return rejectWithValue(error.response.data)
  }
})

export const resetPassword = createAsyncThunk('auth/resetPassword', async (body: any, { rejectWithValue }) => {
  try {
    const { data } = await axiosInstance.patch('/auth-worker/reset-password', body)

    showNotification(ToastVersions.success, data.message)

    return data
  } catch (error: any) {
    showNotification(ToastVersions.error, error.response.data.message)

    return rejectWithValue(error.response.data)
  }
})
