import { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { Path } from 'libraries/router'
import { getCookie, removeCookie } from 'libraries/cookie'

const useAuthentication = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false)

  useEffect(() => {
    const token = getCookie('token') ?? false
    const bol = Boolean(token)

    setIsAuthenticated(bol)
  }, [location])

  // const worker = getCookie('worker') ? JSON.parse(String(getCookie('worker')) ?? '') : null
  // const token = getCookie('token') || null
  // const isAuthenticated = !isNil(worker) && !!token

  const handleLogout = () => {
    navigate(Path.SignIn)

    removeCookie('worker')
    removeCookie('token')
    removeCookie('refreshToken')
  }

  return { isAuthenticated, handleLogout, user: null }
}

export default useAuthentication
