import { FC, useRef, useState } from 'react'
import classNames from 'classnames'
import { noop } from 'lodash'

import useOutsideClick from 'hooks/useOnClickOutside'
import { EButtonVariants, ESizes } from 'components/types'
import { Button, CreateLesson, Lesson, Text } from 'components'
import { AcademicIcon, ArrowLeftIcon, CloseIcon, EventsIcon, NewsIcon, PlusSecondaryIcon } from 'assets'

import { createLessonData } from './utils'
import styles from './CreateLessonDropdown.module.scss'
import { TLessonProps } from '../Lesson/types'

const CreateLessonDropdown: FC = () => {
  const ref = useRef<HTMLDivElement>(null)
  const [isLessonOpen, setIsLessonOpen] = useState<boolean>(true)
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false)

  const onOutsideClickCallback = () => {
    setIsDropdownOpen(false)
  }

  useOutsideClick(ref, onOutsideClickCallback)

  const renderCreateLesson = createLessonData.map(({ lessonVersion, navigateTo }, i) => (
    <CreateLesson key={i} lessonVersion={lessonVersion} navigateTo={navigateTo} />
  ))
  const handleDropdownOpen = () => {
    setIsDropdownOpen(prevOpen => !prevOpen)
  }

  const handleLessonOpen = () => {
    setIsLessonOpen(prevOpen => !prevOpen)
  }

  const lessonData: TLessonProps[] = [
    {
      title: 'Lesson',
      Icon: AcademicIcon,
      onClick: handleLessonOpen,
      text: 'Lorem ipsum dolor sit amet consectetur. At dignissim tempor aliquam malesuada fames quam facilisis.',
    },
    {
      Icon: NewsIcon,
      onClick: noop,
      title: 'News',
      text: 'Lorem ipsum dolor sit amet consectetur. At dignissim tempor aliquam malesuada fames quam facilisis.',
    },
    {
      Icon: EventsIcon,
      onClick: noop,
      title: 'Event',
      text: 'Lorem ipsum dolor sit amet consectetur. At dignissim tempor aliquam malesuada fames quam facilisis.',
    },
  ]

  const renderLesson = lessonData.map(({ title, Icon, text, onClick }, i) => (
    <Lesson key={i} title={title} Icon={Icon} onClick={onClick} text={text} />
  ))

  return (
    <div className={styles.wrapper} ref={ref}>
      <Button
        size={ESizes.Large}
        onClick={handleDropdownOpen}
        variant={EButtonVariants.Primary}
        className={classNames(styles.wrapper__btn, { [styles.wrapper__open]: isDropdownOpen })}
        LeftIcon={isDropdownOpen ? CloseIcon : PlusSecondaryIcon}
      >
        {isDropdownOpen ? 'Close' : 'Create'}
      </Button>

      {isDropdownOpen && (
        <div className={styles.wrapper__popup}>
          {isLessonOpen ? (
            <>
              <Text tagName='h2' text='Create' className={styles.wrapper__popup__title} />
              <div className={styles.wrapper__popup__main}>{renderLesson}</div>
            </>
          ) : (
            <>
              <div className={styles.wrapper__title} role='button' onClick={handleLessonOpen}>
                <ArrowLeftIcon />
                <Text tagName='h2' text='Create lesson in' />
              </div>

              <div className={styles.wrapper__create_lesson}>{renderCreateLesson}</div>
            </>
          )}
        </div>
      )}
    </div>
  )
}

export default CreateLessonDropdown
