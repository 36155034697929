import type { FC } from 'react'

import { Tooltip } from 'components'
import { AvatarSecondaryIcon, RoleIcon } from 'assets'

import type { TTeamMemberCardProps } from './types'
import styles from './TeamMemberCard.module.scss'

const TeamMemberCard: FC<TTeamMemberCardProps> = ({ memberData }) => {
  const { name, email, image, roles } = memberData

  return (
    <div className={styles.wrapper}>
      <Tooltip
        Icon={RoleIcon}
        placement='bottom'
        isShowFromStart={false}
        title={roles.join(', ')}
        label={String(roles.length)}
        className={styles.wrapper__tooltip}
      />

      <div className={styles.wrapper__image_container}>
        {image ? (
          <img className={styles.wrapper__image} src={image} alt='team member image' width='100%' height='100%' />
        ) : (
          <AvatarSecondaryIcon />
        )}
      </div>

      <div>
        <h4 className={styles.wrapper__name}>{name}</h4>

        <p className={styles.wrapper__email}>{email}</p>
      </div>
    </div>
  )
}

export default TeamMemberCard
