import type { FC } from 'react'

import { Button } from 'components'
import { EnterIcon, SupportIcon } from 'assets'
import { ESizes, EButtonVariants } from 'components/types'
import type { TSidebarOpenProps } from 'components/layout/Sidebar/types'

import styles from './SidebarFooter.module.scss'

const SidebarFooter: FC<TSidebarOpenProps> = ({ open, isHaveSupportButton = true }) => (
  <div className={styles.wrapper}>
    <div className={styles.wrapper__inner}>
      {isHaveSupportButton && (
        <Button
          RightIcon={SupportIcon}
          size={ESizes.Large}
          variant={EButtonVariants.Secondary}
          className={styles.wrapper__inner__button}
        >
          {open && 'Support'}
        </Button>
      )}
      <Button
        RightIcon={EnterIcon}
        size={ESizes.Large}
        variant={EButtonVariants.Tertiary}
        className={styles.wrapper__inner__button}
      >
        {open && 'Go to App'}
      </Button>
    </div>
  </div>
)

export default SidebarFooter
