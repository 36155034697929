import { object, string, ref, boolean } from 'yup'

const signUpScheme = object().shape({
  firstName: string()
    .required('firstNameRequired')
    .matches(/^[^\s]+$/, 'firstNameWithoutWhitespace')
    .min(2, 'firstNameMinLength')
    .max(30, 'firstNameMaxLength'),
  lastName: string()
    .required('lastNameRequired')
    .matches(/^[^\s]+$/, 'lastNameWithoutWhitespace')
    .min(2, 'lastNameMinLength')
    .max(30, 'lastNameMaxLength'),
  password: string()
    .required('passwordRequired')
    .min(8, 'passwordMinLength')
    .max(20, 'passwordMaxLength')
    .matches(
      /^(?=(?:.*[A-Z]){1,})(?=(?:.*[a-z]){1,})(?=(?:.*\d){1,})(?=(?:.*[!@#$%^&*().\-_=+{};:,<.>]){1,})(?=\S*[^\s])([A-Za-z0-9!@#$%^&*()\-_=+{};:,<.> \t]{6,20})/,
      'passwordValidate'
    )
    .test('passwords-match', 'passwordMustMatch', function (value) {
      if (this.parent.confirmPassword) {
        return this.parent.confirmPassword === value
      }

      return true
    }),
  confirmPassword: string()
    .required('confirmPasswordRequired')
    .oneOf([ref('password')], 'passwordMustMatch'),
  privacyCondition: boolean().required('privacyPolicyRequired').oneOf([true], 'privacyPolicyRequired'),
})

export default signUpScheme
