import { FC, Suspense, useEffect, useMemo } from 'react'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'

import { useAuthentication } from 'hooks'
import { routesList, Path } from 'libraries/router'
import { HelmetLayout, RouteLoader } from 'components'

import DashboardLayout from '../DashboardLayout'

const PageLayout: FC = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { isAuthenticated } = useAuthentication()

  const emptyRoute = pathname === '/'

  const isHome = isAuthenticated ? Path.Dashboard : Path.SignIn

  useEffect(() => {
    if (emptyRoute) {
      navigate(isHome)
    }
  }, [emptyRoute, isHome, navigate])

  const renderRoutes = useMemo(
    () =>
      routesList(!!isAuthenticated)?.map(({ element: Element, path, title, isPrivate }) => (
        <Route
          key={path}
          path={path}
          element={
            <HelmetLayout title={title}>
              <DashboardLayout isAuthenticated={isAuthenticated} isPrivate={isPrivate}>
                <Suspense fallback={<RouteLoader />}>
                  <Element />
                </Suspense>
              </DashboardLayout>
            </HelmetLayout>
          }
        />
      )),
    [isAuthenticated]
  )

  return <Routes>{renderRoutes}</Routes>
}

export default PageLayout
