import type { FC } from 'react'
import { noop } from 'lodash'
import classNames from 'classnames'
import { NavLink } from 'react-router-dom'

import { ESizes } from 'types'
import { Text } from 'components'

import type { ILink } from './types'
import styles from './Link.module.scss'

const Link: FC<ILink> = ({
  title,
  disabled,
  children,
  LeftIcon,
  ariaLabel,
  className,
  RightIcon,
  navigateTo,
  isFillIgnore,
  ariaLabeledBy,
  onClick = noop,
  outsideNavigate,
  isTranslateIgnore,
  size = ESizes.Small,
}) => {
  const allLinkClassName = classNames(className, styles.wrapper, styles[size], {
    [styles.disabled]: disabled,
    [styles.wrapper__filled]: !isFillIgnore,
  })

  return (
    <NavLink
      title={title}
      aria-label={ariaLabel}
      aria-labelledby={ariaLabeledBy}
      aria-disabled={disabled}
      to={navigateTo}
      onClick={onClick}
      className={allLinkClassName}
      target={outsideNavigate ? '__blank' : '_self'}
      data-testid='nav-link'
    >
      {LeftIcon ? <LeftIcon data-testid='left-icon' /> : null}
      {isTranslateIgnore ? children : <Text emptyTag dataTestId='link-text' text={`${children}`} />}
      {RightIcon ? <RightIcon data-testid='right-icon' /> : null}
    </NavLink>
  )
}

export default Link
