import type { FC } from 'react'
import classNames from 'classnames'

import { Text } from 'components'

import type { TBadgeProps } from './types'
import styles from './Badge.module.scss'

const Badge: FC<TBadgeProps> = ({ text = '', contentClassName = '', className = '', status = '' }) => (
  <div
    className={classNames(styles.container, {
      [className]: className,
    })}
  >
    <Text
      text={text}
      className={classNames(styles.container__situation, {
        [contentClassName]: contentClassName,
        [styles.container__situation_paid]: status === 'paid',
        [styles.container__situation_failed]: status === 'failed',
        [styles.container__situation_pending]: status === 'pending',
      })}
    />
  </div>
)

export default Badge
