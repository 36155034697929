import { FC } from 'react'
import { useNavigate } from 'react-router-dom'

import { EmailIcon } from 'assets'
import { Modal, Text } from 'components'
import { Path } from 'libraries/router/types'

import styles from './VerifyEmail.module.scss'

const VerifyEmailModal: FC<any> = ({ open, email, onClose }) => {
  const navigate = useNavigate()

  return (
    <Modal open={open} onClose={onClose} title='Verify Email'>
      <div className={styles.wrapper}>
        <p className={styles.wrapper__label}>
          <Text text='SentLink' tagName='span' /> {` ${email} `}
          <Text text='SentLinkSecond' tagName='span' />
        </p>

        <div className={styles.wrapper__email__notify}>
          <div className={styles.wrapper__email__notify__icon}>
            <EmailIcon />
          </div>
          <p className={styles.wrapper__email__notify__text}>
            <Text emptyTag text='EmailVerification' />
          </p>
        </div>

        <p role='button' className={styles.wrapper__link} onClick={() => navigate(Path.SignIn)}>
          <Text emptyTag text='ReturnToSignIn' />
        </p>
      </div>
    </Modal>
  )
}

export default VerifyEmailModal
