import type { FC } from 'react'
import { noop } from 'lodash'
import classNames from 'classnames'

import { SortByIcon } from 'assets'
import { Text } from 'components'

import { PaymentsTableHeaderData } from './utils'
import styles from './PaymentsTableHeader.module.scss'

const PaymentsTableHeader: FC = () => {
  const sortHandler = (id: number) => {
    // eslint-disable-next-line no-console
    console.log(id)
  }

  const tableHeader = PaymentsTableHeaderData.map(item => (
    <div
      key={item.id}
      className={classNames(styles.header_items, {
        [styles.header_items_sort]: item.sortable,
        [styles.header_items__status]: item.rightAligned,
      })}
    >
      <Text
        text={item.name}
        RightIcon={item.sortable ? SortByIcon : null}
        className={classNames(styles.header_items_text, {
          [styles.header_items_text_right]: item.rightAligned,
          [styles.header_items_text_center]: item.centerAligned,
          [styles.header_items_text_button]: item.sortable,
        })}
        onClick={item.sortable ? () => sortHandler(item.id) : noop}
      />
    </div>
  ))

  return <div className={styles.header}>{tableHeader}</div>
}

export default PaymentsTableHeader
