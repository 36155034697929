import { type FC, useState } from 'react'
import classNames from 'classnames'

import { UploadSecondaryIcon } from 'assets'

import type { TAvatProps } from './types'
import { handleFileSelect } from './utils'
import styles from './Avatar.module.scss'
import AvatarCrop from './AvatarCrop'

const Avatar: FC<TAvatProps> = ({ imageValue, onSaveHandler, className = '' }) => {
  const [image, setImage] = useState<string>(imageValue)
  const [showModal, setShowModal] = useState<boolean>(false)

  return (
    <div className={classNames(styles.wrapper, { [className]: className })}>
      <div className={styles.wrapper__edit}>
        <label htmlFor='imageUpload' className={styles.wrapper__edit__label}>
          <UploadSecondaryIcon />
        </label>

        <input
          type='file'
          accept='image/*'
          id='imageUpload'
          className={styles.wrapper__edit__input}
          onChange={event => handleFileSelect(event, setImage, setShowModal)}
        />
      </div>

      {!showModal && (
        <img
          width='100%'
          height='100%'
          loading='lazy'
          alt='Uploaded image'
          src={imageValue}
          className={styles.wrapper__image}
        />
      )}

      <AvatarCrop setShowModal={setShowModal} image={image} onSaveHandler={onSaveHandler} open={showModal} />
    </div>
  )
}
export default Avatar
