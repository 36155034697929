import type { FC } from 'react'
import { NavLink } from 'react-router-dom'

import { Text } from 'components'
import { programInfoIconMapper, programInfoTextMapper } from 'utils'

import type { ICreateLessonProps } from './types'
import styles from './CreateLesson.module.scss'

const CreateLesson: FC<ICreateLessonProps> = ({ lessonVersion, navigateTo }) => {
  const Icon = programInfoIconMapper[lessonVersion]

  return (
    <NavLink to={navigateTo} className={styles.wrapper}>
      <Icon className={styles.wrapper__icon} />
      <Text text={programInfoTextMapper[lessonVersion]} className={styles.wrapper__text} />
    </NavLink>
  )
}

export default CreateLesson
