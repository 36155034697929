export enum Path {
  Error = '*',
  SignIn = '/sign-in',
  SignUp = '/invite',
  ResetPassword = '/resetPassword',
  ForgotPassword = '/forgotPassword',

  Team = '/team',
  Stats = '/stats',
  Board = '/board/:degree',
  AdminPage = '/admin',
  Payments = '/payments',
  Dashboard = '/dashboard',
  BecomeExpert = '/become-expert',
  BoardMaster = '/board/master',
  CreateLesson = '/:degree/create-course',
  BoardBachelor = '/board/bachelor',
  BoardExpert = '/board/expert',
  BoardProfessor = '/board/professor',
  CreateQuestion = '/:degree/create-course/:id',
  BoardFreeLessons = '/board/free',
}
