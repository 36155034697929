import type { FC } from 'react'

import { Text } from 'components'
import { PlusPrimaryIcon } from 'assets'

import type { TAddTopicProps } from './types'
import styles from './AddButton.module.scss'

const AddTopic: FC<TAddTopicProps> = ({ onClick, title }) => (
  <div className={styles.wrapper} role='button' onClick={onClick}>
    <PlusPrimaryIcon />

    <Text className={styles.wrapper__btn__text} text={title} />
  </div>
)

export default AddTopic
