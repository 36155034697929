import type { TRoleItemProps } from 'components/atom/RoleItem/types'

export const rolesFakeData: TRoleItemProps[] = [
  {
    id: 1,
    title: 'Role 1',
    permissions: [
      {
        id: 0,
        selected: true,
        name: 'role1',
      },
      {
        id: 1,
        selected: false,
        name: 'role2',
      },
      {
        id: 2,
        selected: true,
        name: 'role3',
      },
    ],
  },
  {
    id: 2,
    title: 'Role 2',
    permissions: [
      {
        id: 0,
        selected: true,
        name: 'role1',
      },
      {
        id: 1,
        selected: false,
        name: 'role2',
      },
      {
        id: 2,
        selected: true,
        name: 'role3',
      },
    ],
  },

  {
    id: 3,
    title: 'Role 3',
    permissions: [
      {
        id: 0,
        selected: true,
        name: 'role1',
      },
      {
        id: 1,
        selected: false,
        name: 'role2',
      },
      {
        id: 2,
        selected: true,
        name: 'role3',
      },
    ],
  },

  {
    id: 4,
    title: 'Role 4',
    permissions: [
      {
        id: 0,
        selected: true,
        name: 'role1',
      },
      {
        id: 1,
        selected: false,
        name: 'role2',
      },
      {
        id: 2,
        selected: true,
        name: 'role3',
      },
    ],
  },
]
