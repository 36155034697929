import type { FC } from 'react'
import classNames from 'classnames'

import { Text } from 'components'

import type { ISidebarMenuSectionProps } from '../types'
import SidebarMenuItem from './SidebarMenuItem'
import styles from './SidebarMenu.module.scss'

const SidebarSection: FC<ISidebarMenuSectionProps> = ({ open, setOpen, title, menuItems }) => {
  const sectionTitleClassName = classNames({
    [styles.wrapper__section__title]: open,
    [styles.wrapper__closed__section__title]: !open,
  })

  const sectionWrapperClassName = classNames(styles.wrapper__section, {
    [styles.wrapper__closed__section]: !open,
  })

  const renderItems = menuItems.map(({ text, navigateTo, Icon }, idx) => (
    <SidebarMenuItem setOpen={setOpen} Icon={Icon} navigateTo={navigateTo} text={text} open={open} key={idx} />
  ))

  return (
    <div className={sectionWrapperClassName}>
      <div className={sectionTitleClassName}>{open && <Text tagName='span' text={title} />}</div>
      <div className={styles.wrapper__section__menu}>{renderItems}</div>
    </div>
  )
}

export default SidebarSection
