export enum EPlacementVariants {
  Right = 'right',
  Left = 'left',
  Top = 'top',
  Bottom = 'bottom',
}

export enum EButtonVariants {
  Primary = 'primary',
  Secondary = 'secondary',
  Tertiary = 'tertiary',
}

export enum ELanguage {
  en = 'en',
  ru = 'ru',
}

export enum ESizes {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}

export type TResults = {
  wrongAnswers: number
  correctAnswers: number
}

export type TModalProps = {
  openModal: boolean
  handleClose: () => void
}

export type TTranslatableField = {
  ru: string
  en: string
}

export enum EQuestionType {
  Checkbox = 'checkbox',
  Radio = 'radio',
  TextArea = 'textarea',
}

export enum EProgram {
  Free = 'Free',
  Master = 'Master',
  Expert = 'Expert',
  Bachelor = 'Bachelor',
  Professor = 'Professor',
  FreeLesson = 'Free Lesson',
}

export enum ETopics {
  Topics = 'Topics',
  Lessons = 'Lessons',
}

export enum EInput {
  TextType = 'text',
  EmailType = 'email',
  PasswordType = 'password',
}
