import type { FC } from 'react'
import { isUndefined } from 'lodash'
import { NavLink } from 'react-router-dom'
import { Button } from '@amatuni-crypto-academy/amatuni-crypto-academy-education'

import { PROJECT_TITLE } from 'utils'
import { useAuthentication } from 'hooks'
import { AmatuniPrimaryIcon } from 'assets'
import { getCookie } from 'libraries/cookie'
import { Path } from 'libraries/router/types'
import { ModeSwitch, LangDropdown, CreateLessonDropdown } from 'components'

import styles from './Header.module.scss'

const Header: FC = () => {
  // @TODO-_-web3 function is not used
  // const { addresses } = useAppSelector(web3Selectors.chain)

  const user = isUndefined(getCookie('worker'))

  const { handleLogout } = useAuthentication()

  return (
    <header className={styles.wrapper}>
      <div className={styles.wrapper__left}>
        <ModeSwitch />
        {/* <div className={styles.wrapper__left__btn}>
          <WalletDropdown />
        </div> */}
        <LangDropdown />
      </div>

      <NavLink to={!user ? Path.Dashboard : Path.SignIn} className={styles.wrapper__center}>
        <AmatuniPrimaryIcon />

        <h1 className={styles.wrapper__center__text}>{PROJECT_TITLE}</h1>
      </NavLink>

      <div className={styles.wrapper__right}>
        <CreateLessonDropdown />
        <Button onClick={handleLogout} size='large' variant='primary'>
          Log out
        </Button>
      </div>
    </header>
  )
}

export default Header
