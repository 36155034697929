import type { FC } from 'react'
import { Button } from '@amatuni-crypto-academy/amatuni-crypto-academy-education'

import { Text } from 'components'
import { CreditCardIcon, EditIcon, V1PlusIcon } from 'assets'
import { programInfoPrimaryIconMapper, programInfoTextMapper } from 'utils'

import type { TBoardHeaderProps } from './types'
import styles from './BoardHeader.module.scss'

const BoardHeader: FC<TBoardHeaderProps> = ({ version, cardPrice = 0 }) => {
  const Icon = programInfoPrimaryIconMapper[version]

  return (
    <div className={styles.wrapper}>
      <div className={styles.wrapper__left}>
        <Icon />
        <Text className={styles.wrapper__left__text} tagName='p' text={programInfoTextMapper[version] + ' Program'} />
      </div>

      <div className={styles.wrapper__right}>
        <div className={styles.wrapper__right__card}>
          <CreditCardIcon />
          <Text text='Credit Card Price:' className={styles.wrapper__right__card__text} />
          <Text text={String(cardPrice) + '$'} className={styles.wrapper__right__card__price} />
        </div>

        <div className={styles.wrapper__right__btns}>
          <Button size='large' variant='tertiary' RightIcon={EditIcon}>
            Edit
          </Button>

          <Button size='large' variant='primary' LeftIcon={V1PlusIcon}>
            Add Lesson
          </Button>
        </div>
      </div>
    </div>
  )
}

export default BoardHeader
