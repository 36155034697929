import { createSlice } from '@reduxjs/toolkit'

import type { TInitialState } from './types'
import { adminInvitation, rolePermission, checkInvitedUserToken, createAdminReq, adminWorkersGet } from './actions'

export const initialState: TInitialState = {
  adminInvitation: {
    data: null,
    error: null,
    loading: false,
  },
  rolePermission: {
    data: null,
    error: null,
    loading: false,
  },
  checkInvitedUserToken: {
    data: null,
    error: null,
    loading: false,
  },
  createAdminReq: {
    data: null,
    error: null,
    loading: false,
  },
  adminWorkersGet: {
    data: null,
    error: null,
    loading: false,
  },
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(adminInvitation.pending, state => {
      state.adminInvitation.loading = true
      state.adminInvitation.error = null
    })
    builder.addCase(adminInvitation.fulfilled, (state, { payload }) => {
      state.adminInvitation.loading = false
      state.adminInvitation.error = null
      state.adminInvitation.data = payload
    })
    builder.addCase(adminInvitation.rejected, (state, action) => {
      state.adminInvitation.loading = false
      state.adminInvitation.error = action.payload as null
    })

    //
    builder.addCase(rolePermission.pending, state => {
      state.rolePermission.loading = true
      state.rolePermission.error = null
    })
    builder.addCase(rolePermission.fulfilled, (state, { payload }) => {
      state.rolePermission.loading = false
      state.rolePermission.error = null
      state.rolePermission.data = payload
    })
    builder.addCase(rolePermission.rejected, (state, action) => {
      state.rolePermission.loading = false
      state.rolePermission.error = action.payload as null
    })

    //
    builder.addCase(checkInvitedUserToken.pending, state => {
      state.checkInvitedUserToken.loading = true
      state.checkInvitedUserToken.error = null
    })
    builder.addCase(checkInvitedUserToken.fulfilled, (state, { payload }) => {
      state.checkInvitedUserToken.loading = false
      state.checkInvitedUserToken.error = null
      state.checkInvitedUserToken.data = payload
    })
    builder.addCase(checkInvitedUserToken.rejected, (state, action) => {
      state.checkInvitedUserToken.loading = false
      state.checkInvitedUserToken.error = action.payload as null
    })

    //
    builder.addCase(createAdminReq.pending, state => {
      state.createAdminReq.loading = true
      state.createAdminReq.error = null
    })
    builder.addCase(createAdminReq.fulfilled, (state, { payload }) => {
      state.createAdminReq.loading = false
      state.createAdminReq.error = null
      state.createAdminReq.data = payload
    })
    builder.addCase(createAdminReq.rejected, (state, action) => {
      state.createAdminReq.loading = false
      state.createAdminReq.error = action.payload as null
    })

    //
    builder.addCase(adminWorkersGet.pending, state => {
      state.adminWorkersGet.loading = true
      state.adminWorkersGet.error = null
    })
    builder.addCase(adminWorkersGet.fulfilled, (state, { payload }) => {
      state.adminWorkersGet.loading = false
      state.adminWorkersGet.error = null
      state.adminWorkersGet.data = payload
    })
    builder.addCase(adminWorkersGet.rejected, (state, action) => {
      state.adminWorkersGet.loading = false
      state.adminWorkersGet.error = action.payload as null
    })
  },
})

const authReducer = authSlice.reducer

export default authReducer
