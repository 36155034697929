import { EProgram } from 'types'
import {
  PdfIcon,
  ExamIcon,
  TestIcon,
  VideoIcon,
  TopicIcon,
  ExpertIcon,
  FreeLessonsPrimaryIcon,
  FreeLessonsSecondaryIcon,
  MasterSecondaryIcon,
  BachelorSecondaryIcon,
  ExpertSecondaryIcon,
  ProfessorSecondaryIcon,
  MasterPrimaryIcon,
  BachelorPrimaryIcon,
  ExpertPrimaryIcon,
  ProfessorPrimaryIcon,
} from 'assets'

export const programInfoIconMapper = {
  [EProgram.Master]: MasterSecondaryIcon,
  [EProgram.Expert]: ExpertSecondaryIcon,
  [EProgram.Bachelor]: BachelorSecondaryIcon,
  [EProgram.Professor]: ProfessorSecondaryIcon,
  [EProgram.Free]: FreeLessonsSecondaryIcon,
  [EProgram.FreeLesson]: FreeLessonsSecondaryIcon,
}

export const programInfoPrimaryIconMapper = {
  [EProgram.Free]: FreeLessonsPrimaryIcon,
  [EProgram.FreeLesson]: FreeLessonsPrimaryIcon,
  [EProgram.Master]: MasterPrimaryIcon,
  [EProgram.Expert]: ExpertPrimaryIcon,
  [EProgram.Bachelor]: BachelorPrimaryIcon,
  [EProgram.Professor]: ProfessorPrimaryIcon,
}

export const programInfoTextMapper = {
  [EProgram.Master]: 'Master',
  [EProgram.Bachelor]: 'Bachelor ',
  [EProgram.Expert]: 'Expert',
  [EProgram.Professor]: 'Professor',
  [EProgram.Free]: 'Free',
  [EProgram.FreeLesson]: 'Free Lesson',
}

export const lessonIntroBlockMapper = {
  1: EProgram.Bachelor,
  2: EProgram.Expert,
  3: EProgram.Master,
  4: EProgram.Professor,
  5: EProgram.FreeLesson,
}

export const lessonMaterialIconMapper = {
  1: TopicIcon,
  2: VideoIcon,
  3: TestIcon,
  4: ExamIcon,
  5: ExpertIcon,
  6: PdfIcon,
}
