import { lazy } from 'react'

import { PROJECT_TITLE } from 'utils'

import { Path } from './types'

const SignIn = lazy(() => import('pages/Auth/SignIn'))
const SignUp = lazy(() => import('pages/Auth/SignUp'))
const ResetPassword = lazy(() => import('pages/Auth/ResetPassword'))
const ForgotPassword = lazy(() => import('pages/Auth/ForgotPassword'))

const Board = lazy(() => import('pages/Board'))
const Home = lazy(() => import('pages/Dashboard'))
const Payments = lazy(() => import('pages/Payments'))
const Team = lazy(() => import('pages/TeamManagement'))
const AdminPage = lazy(() => import('pages/AdminPage'))
const BecomeExpert = lazy(() => import('pages/BecomeExpert'))
const CreateLesson = lazy(() => import('pages/CreateLesson'))

const Error = lazy(() => import('pages/Error'))

const routesList = (isAuth: boolean) => {
  if (!isAuth) {
    return [
      {
        element: SignIn,
        path: Path.SignIn,
        title: `${PROJECT_TITLE} Login`,
        isPrivate: false,
      },
      {
        element: SignUp,
        path: Path.SignUp,
        title: `${PROJECT_TITLE} Register`,
        isPrivate: false,
      },
      {
        element: ForgotPassword,
        path: Path.ForgotPassword,
        title: 'Forgot Password',
        isPrivate: false,
      },
      {
        element: ResetPassword,
        path: Path.ResetPassword,
        title: 'Reset Password',
        isPrivate: false,
      },
      {
        element: Error,
        path: Path.Error,
        isPrivate: true,
        title: 'Error',
      },
    ]
  } else {
    return [
      {
        element: Home,
        path: Path.Dashboard,
        isPrivate: true,
        title: 'Dashboard',
      },
      {
        element: Team,
        path: Path.Team,
        isPrivate: true,
        title: 'Team management',
      },
      {
        element: BecomeExpert,
        path: Path.BecomeExpert,
        isPrivate: true,
        title: 'Become Expert',
      },
      {
        element: AdminPage,
        path: Path.AdminPage,
        isPrivate: true,
        title: `${PROJECT_TITLE} Admin Page`,
      },
      {
        element: CreateLesson,
        path: Path.CreateLesson,
        isPrivate: true,
        title: 'Create Lesson',
      },
      {
        element: Board,
        path: Path.Board,
        isPrivate: true,
        title: 'Board',
      },
      {
        element: Payments,
        path: Path.Payments,
        isPrivate: true,
        title: 'Payments',
      },
      {
        element: Error,
        path: Path.Error,
        isPrivate: true,
        title: 'Error',
      },
    ]
  }
}

export default routesList
