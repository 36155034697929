import type { FC } from 'react'

import { useLanguageTranslation } from 'hooks'
import { Modal, Text, Badge } from 'components'

import { formatDate, returnIcon } from '../PaymentsTableItem/utils'
import type { IUserPaymentInfoModalProps } from './types'
import styles from './UserPaymentInfoModal.module.scss'

const UserPaymentInfoModal: FC<IUserPaymentInfoModalProps> = ({ item, onClose }) => {
  const { language } = useLanguageTranslation()

  const RenderIcon = returnIcon(item.type)

  const tableModalData = [
    { id: 0, title: 'ID', value: `#${item?.id}` },
    { id: 1, title: 'Type', value: item?.type, ValueLeftIcon: RenderIcon },
    { id: 2, title: 'Faculty', value: item?.faculty[language] },
    { id: 3, title: 'Name', value: item?.name[language] },
    { id: 4, title: 'Method', value: item?.method },
    { id: 5, title: 'CreatedAt', value: `${formatDate(item.createdAt)}` },
    { id: 6, title: 'Currency', value: item?.currency },
    { id: 7, title: 'Amount', value: `${(Number(item?.amount) ?? 0) / 100} ${item?.currency}` },
    { id: 8, title: 'Availability', value: item?.availability },
    { id: 9, title: 'TableStatus', type: 'badge', value: `${item.status}` },
  ]

  const returnTableModalContent = tableModalData.map(element => (
    <div key={element.id} className={styles.content__item}>
      <Text text={element.title} RText=':' className={styles.content__item__name} />
      {element.type === 'badge' ? (
        <Badge contentClassName={styles.content__item__badge} text={item.status} status={item.status} />
      ) : (
        <Text
          text={element.value}
          RightIcon={element.ValueLeftIcon ? element.ValueLeftIcon : null}
          className={styles.content__item__value}
        />
      )}
    </div>
  ))

  return (
    <Modal title='Payments' open={Boolean(item.id)} onClose={onClose}>
      <div className={styles.content}>{returnTableModalContent}</div>
    </Modal>
  )
}

export default UserPaymentInfoModal
