import { FC, useState } from 'react'
import classNames from 'classnames'

import { booleanStateUtil } from 'utils'
import { MoonIcon, SunIcon } from 'assets'
import { getCookie, setCookie } from 'libraries/cookie'

import styles from './ModeSwitch.module.scss'

const ModeSwitch: FC = () => {
  const modeSwitch = Number(getCookie('mode') ?? 0)
  const [switchActive, setSwitchToActive] = useState<number>(modeSwitch)

  const onActiveSwitchCallback = () => {
    setSwitchToActive(booleanStateUtil(switchActive))
    setCookie('mode', String(booleanStateUtil(switchActive)))
  }

  return (
    <div onClick={onActiveSwitchCallback} className={styles.wrapper}>
      <div
        className={classNames(styles.wrapper__item, {
          [styles.wrapper__light_active]: !switchActive,
        })}
      >
        <MoonIcon />
      </div>
      <div
        className={classNames(styles.wrapper__switcher, styles.wrapper__item, {
          [styles.wrapper__switcher_active]: switchActive,
        })}
      ></div>
      <div
        className={classNames(styles.wrapper__item, {
          [styles.wrapper__light_active]: switchActive,
        })}
      >
        <SunIcon />
      </div>
    </div>
  )
}

export default ModeSwitch
