import { useEffect, type FC, useState, useCallback, Fragment } from 'react'
import { isEmpty, isUndefined } from 'lodash'
import { format } from 'date-fns'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Button, Loader, Modal, Input } from '@amatuni-crypto-academy/amatuni-crypto-academy-education'

import { emailScheme } from 'utils'
import { RoleItem } from 'components'
import { InvitationSelector } from 'store/invite/selector'
import { useAppDispatch, useAppSelector } from 'libraries/redux'
import { createAdminReq, rolePermission } from 'store/invite/actions'
import type { TPermissions, TRolePermission } from 'store/invite/types'

import type { IWorkerInvitationProps, TSubmitBody } from './types'
import styles from './WorkerInvitationModal.module.scss'

const WorkerInvitationModal: FC<IWorkerInvitationProps> = ({ open, onClose, isInvitation }) => {
  const dispatch = useAppDispatch()
  const { data } = useAppSelector(InvitationSelector.rolePermissionSelector)
  const { loading, error } = useAppSelector(InvitationSelector.createAdminReqPostSelector)

  const [roles, setRoles] = useState<{ [key: number]: number[] }>([])
  const [submitRoles, setSubmitRoles] = useState<boolean | undefined>(undefined)

  const currentDate = new Date()
  currentDate.setDate(currentDate.getDate() + 2)
  const formattedDate = format(currentDate, 'yyyy-MM-dd')

  const onItemPress = useCallback((include: boolean, permission: TPermissions, roleId: number) => {
    setRoles(previousRoles => {
      if (include) {
        const roleWithoutPermission = !isUndefined(permission.id)
          ? [...(previousRoles[roleId] || []), permission?.id]
          : [...(previousRoles[roleId] || [])]

        previousRoles[roleId] = roleWithoutPermission
      } else {
        if (previousRoles[roleId]?.length === 1) {
          delete previousRoles[roleId]
        } else {
          previousRoles[roleId] = previousRoles[roleId]?.filter(permissionId => permissionId !== permission?.id)
        }
      }

      return { ...previousRoles }
    })
  }, [])

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<any>({
    mode: 'onChange',
    resolver: yupResolver(emailScheme),
  })

  const renderRolls = data?.map((item: TRolePermission) => (
    <RoleItem
      permissions={item?.permissions}
      title={item?.name.replace(/\_/g, ' ')}
      key={item?.id + item?.name + !!item?.permissions?.length}
      changeHandler={(include: boolean, permission: TPermissions) => onItemPress(include, permission, item?.id)}
    />
  ))

  const modiFyRolePermissionsForSubmit = Object.keys(roles)?.map(key => ({
    roleId: Number(key),
    permissions: roles[Number(key)],
  }))

  const onSubmit = (data: { email: string }) => {
    const submitData: TSubmitBody = {
      email: data?.email,
      expiredAt: formattedDate,
      workerRoles: modiFyRolePermissionsForSubmit,
    }

    if (submitData?.email && submitData?.workerRoles && submitData?.expiredAt) {
      setSubmitRoles(true)

      dispatch(createAdminReq(submitData))
      reset()
    }
  }

  const closeHandler = () => {
    onClose()
    setRoles([])
    setSubmitRoles(undefined)
  }

  const sendIt = submitRoles ? (
    <Loader size='90px' className={styles.loader} />
  ) : (
    <p className={styles.invitation_sent}>
      {error?.statusCode === 400 ? 'Please try again, you have some problem.' : 'Invitation sent'}
    </p>
  )
  const modalTitle = isUndefined(submitRoles) ? `${isInvitation ? 'Worker Invitation' : 'Roles & Permissions'}` : ''

  useEffect(() => {
    if (submitRoles && !loading) {
      setSubmitRoles(false)
    }
  }, [loading, submitRoles])

  useEffect(() => {
    dispatch(rolePermission())

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Modal
      open={open}
      onClose={closeHandler}
      className={styles.wrapper}
      contentClassName={styles.wrapper__modal_content}
      title={modalTitle}
    >
      {isUndefined(submitRoles) && (
        <Fragment>
          <p className={styles.wrapper__subtitle}>
            The invited admin has time to accept the invitation before {formattedDate}, after which the invitation will
            be expired.
          </p>

          <form onSubmit={handleSubmit(onSubmit)} className={styles.wrapper__container}>
            <div className={styles.wrapper__inputs}>
              <Input
                name='email'
                size='large'
                register={register}
                label='Email address'
                placeholder='Enter email address'
                containerClass={styles.wrapper__input}
                error={errors.email?.message as string}
              />
            </div>

            <div className={styles.wrapper__divider} />

            <div className={styles.wrapper__container__content}>
              {renderRolls}

              <div className={styles.wrapper__buttons}>
                <Button onClick={onClose} size='large' variant='tertiary'>
                  Cancel
                </Button>

                <Button size='large' disabled={!(isValid && !isEmpty(roles))} variant='primary' type='submit'>
                  {isInvitation ? 'Send invitation' : 'Save'}
                </Button>
              </div>
            </div>
          </form>
        </Fragment>
      )}

      {!isUndefined(submitRoles) ? sendIt : null}
    </Modal>
  )
}

export default WorkerInvitationModal
