import type { FC } from 'react'
import classNames from 'classnames'

import { Button, Input } from 'components'
import { EButtonVariants, ESizes } from 'components/types'
import { DeleteIcon, PlusPrimaryIcon, TestIcon } from 'assets'

import type { TLessonTopicsProps } from './types'
import styles from './LessonTopics.module.scss'

const LessonTopics: FC<TLessonTopicsProps> = ({ placeholder, id, className, onDelete, onAddTest }) => (
  <div className={classNames(styles.wrapper, className)}>
    <div className={styles.wrapper__topic}>
      <p className={styles.wrapper__topic__text}> {id}</p>
    </div>

    <Input name='topic' size={ESizes.Large} placeholder={placeholder} />

    <div className={styles.wrapper__divider} />

    <div className={styles.wrapper__btns}>
      <Button variant={EButtonVariants.Secondary} size={ESizes.Large} RightIcon={TestIcon} onClick={onAddTest}>
        Add Test
      </Button>

      <Button variant={EButtonVariants.Primary} size={ESizes.Large} LeftIcon={PlusPrimaryIcon}>
        Add Lesson
      </Button>

      <Button onClick={onDelete} size={ESizes.Large} variant={EButtonVariants.Tertiary} LeftIcon={DeleteIcon} />
    </div>
  </div>
)

export default LessonTopics
