import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'

import { PageLayout } from 'components'
import { store } from 'libraries/redux'
import ToastServices from 'libraries/toastify/toastServices'

// @TODO-_-web3 function is not used
// import Web3ModalProvider from './libraries/web3/web3ModalProvider'

import './styles/index.global.scss'

const App = () => (
  <BrowserRouter>
    <Provider store={store}>
      {/* <Web3ModalProvider> */}
      <PageLayout />
      <ToastServices />
      {/* </Web3ModalProvider> */}
    </Provider>
  </BrowserRouter>
)

export default App
