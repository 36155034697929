import type { FC } from 'react'
import { noop } from 'lodash'

import { Text } from 'components'
import { ArrowRightIcon } from 'assets'

import type { TLessonProps } from './types'
import styles from './Lesson.module.scss'

const Lesson: FC<TLessonProps> = ({ Icon, text, title, onClick = noop }) => (
  <div className={styles.wrapper} onClick={onClick}>
    <Icon className={styles.wrapper__icon} />

    <div className={styles.wrapper__right}>
      <div className={styles.wrapper__right__top}>
        <Text tagName='h2' text={title} className={styles.wrapper__right__top__title} />
        <ArrowRightIcon />
      </div>

      <Text tagName='p' text={text} className={styles.wrapper__right__text} />
    </div>
  </div>
)

export default Lesson
