import { createSlice } from '@reduxjs/toolkit'

import { signIn, signUp, forgotPassword, resetPassword } from './actions'

import type { IAuthInitialState } from './types'

export const initialState: IAuthInitialState = {
  signIn: {
    data: null,
    error: null,
    loading: false,
  },
  signUp: {
    data: null,
    error: null,
    loading: false,
  },
  forgotPassword: {
    loading: false,
    error: null,
    data: null,
  },
  resetPassword: {
    loading: false,
    error: null,
  },
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(signIn.pending, state => {
      state.signIn.loading = true
      state.signIn.error = null
    })

    builder.addCase(signIn.fulfilled, (state, { payload }) => {
      state.signIn.loading = false
      state.signIn.error = null
      state.signIn.data = payload
    })

    builder.addCase(signIn.rejected, (state, { payload }) => {
      state.signIn.loading = false
      state.signIn.error = payload as null
    })

    builder.addCase(signUp.pending, state => {
      state.signUp.loading = true
      state.signUp.error = null
    })
    builder.addCase(signUp.rejected, (state, { payload }) => {
      state.signUp.loading = false
      state.signUp.error = payload as null
    })
    builder.addCase(signUp.fulfilled, (state, { payload }) => {
      state.signUp.loading = false
      state.signUp.error = null
      state.signUp.data = payload.data
    })

    builder.addCase(forgotPassword.pending, state => {
      state.forgotPassword.error = null
      state.forgotPassword.loading = true
    })

    builder.addCase(forgotPassword.fulfilled, (state, action) => {
      state.forgotPassword.error = null
      state.forgotPassword.loading = false
      state.forgotPassword.data = action.payload
    })

    builder.addCase(forgotPassword.rejected, (state, action) => {
      state.forgotPassword.error = action.payload as null
      state.forgotPassword.loading = false
    })

    builder.addCase(resetPassword.pending, state => {
      state.resetPassword.loading = true
      state.resetPassword.error = null
    })

    builder.addCase(resetPassword.fulfilled, state => {
      state.resetPassword.loading = false
      state.resetPassword.error = null
    })

    builder.addCase(resetPassword.rejected, (state, { payload }) => {
      state.resetPassword.loading = false
      state.resetPassword.error = payload as null
    })
  },
})

const authReducer = authSlice.reducer

export default authReducer
