import type { FC } from 'react'

import { Button, Text } from 'components'

import { ExamIcon } from 'assets'
import { TopicsIconMapper, TopicsTextMapper } from 'utils'
import { EButtonVariants, ESizes } from 'components/types'

import type { TTopicsProps } from './types'
import styles from './Topics.module.scss'

const Topics: FC<TTopicsProps> = ({ topicVariant, isExam }) => {
  const Icon = TopicsIconMapper[topicVariant]

  return (
    <div className={styles.wrapper}>
      <div className={styles.wrapper__empty__block} />

      <div className={styles.wrapper__center}>
        <Icon />
        <Text className={styles.wrapper__center__title} tagName='h3' text={TopicsTextMapper[topicVariant]} />
      </div>

      {isExam && (
        <div className={styles.wrapper__exam}>
          <Button size={ESizes.Large} variant={EButtonVariants.Secondary} LeftIcon={ExamIcon} isFillIgnore>
            Make Exam
          </Button>
        </div>
      )}
    </div>
  )
}

export default Topics
