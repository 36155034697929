import {
  BlogIcon,
  NewsIcon,
  MasterPrimaryIcon,
  SettingsIcon,
  ExpertPrimaryIcon,
  BachelorPrimaryIcon,
  ProfessorPrimaryIcon,
  FreeLessonsPrimaryIcon,
  SupportIcon,
  HistoryIcon,
  TeamIcon,
  WalletIcon,
  ExpertIcon,
  StatsIcon,
} from 'assets'
import { Path } from 'libraries/router/types'

import type { TSectionItem } from './types'

export const sidebarMenuItemsUtils: TSectionItem[] = [
  {
    title: 'General',
    menuItems: [
      {
        Icon: HistoryIcon,
        text: 'Activities',
        navigateTo: Path.Dashboard,
      },
      {
        Icon: TeamIcon,
        text: 'Team Management',
        navigateTo: Path.Team,
      },
      {
        Icon: StatsIcon,
        text: 'Stats',
        navigateTo: Path.Stats,
      },
    ],
  },
  {
    title: 'Academy',
    menuItems: [
      {
        Icon: FreeLessonsPrimaryIcon,
        text: 'Academy',
        navigateTo: Path.BoardFreeLessons,
      },
      {
        Icon: ExpertIcon,
        text: 'Experts',
        navigateTo: Path.BecomeExpert,
      },
    ],
  },
  {
    title: 'Other',
    menuItems: [
      {
        Icon: NewsIcon,
        text: 'News',
        navigateTo: Path.AdminPage,
      },
      {
        Icon: WalletIcon,
        text: 'Wallet',
        navigateTo: Path.Payments,
      },
      {
        Icon: BlogIcon,
        text: 'Library',
        navigateTo: null,
      },
      {
        Icon: SettingsIcon,
        text: 'Settings',
        navigateTo: null,
      },
    ],
  },
]

export const mobileSidebarMenuItemsUtils: TSectionItem[] = [
  {
    title: 'Academy',
    menuItems: [
      {
        Icon: FreeLessonsPrimaryIcon,
        text: 'Free Lessons',
        navigateTo: Path.BoardFreeLessons,
      },
      {
        Icon: BachelorPrimaryIcon,
        text: 'Bachelor ',
        navigateTo: Path.BoardBachelor,
      },
      {
        Icon: ExpertPrimaryIcon,
        text: 'Expert',
        navigateTo: Path.BoardExpert,
      },
      {
        Icon: MasterPrimaryIcon,
        text: 'Master',
        navigateTo: Path.BoardMaster,
      },
      {
        Icon: ProfessorPrimaryIcon,
        text: 'Professor',
        navigateTo: Path.BoardProfessor,
      },
    ],
  },
  {
    title: 'Other',
    menuItems: [
      {
        Icon: SettingsIcon,
        text: 'Settings',
        navigateTo: null,
      },
      {
        Icon: SupportIcon,
        text: 'Support',
        navigateTo: null,
      },
    ],
  },
]
