import { FC, useEffect, useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { isEqual, noop } from 'lodash'
import classNames from 'classnames'

import { Text } from 'components'

import type { IMenuItem } from '../types'
import styles from './SidebarMenu.module.scss'

const SidebarMenuItem: FC<IMenuItem> = ({ setOpen, Icon, navigateTo, text, open }) => {
  const location = useLocation()

  const [visible, setVisible] = useState<boolean>(false)

  const onCloseHandler = () => {
    if (open) setOpen(false)
  }

  const onTransitionHandle = () => {
    if (!open) {
      setVisible(false)
    }
  }

  useEffect(() => {
    if (open) {
      setVisible(true)
    }
  }, [open])

  const menuItemClassName = (isActive?: boolean) => {
    return classNames(styles.wrapper__section__menu__item, {
      [styles.wrapper__closed__section__menu__item]: !open,
      [styles.wrapper__section__menu__active__item]: isActive,
    })
  }

  const menuItemTextClassName = classNames(styles.wrapper__section__menu__item__text, {
    [styles.wrapper__closed__section__menu__item__text]: !open,
    [styles.wrapper__section__menu__active__item__text]: isEqual(navigateTo, location.pathname),
  })

  const itemContent = (
    <>
      <div>
        <Icon />
      </div>
      {visible ? (
        <span onTransitionEnd={onTransitionHandle} className={menuItemTextClassName}>
          <Text text={text} />
        </span>
      ) : null}
    </>
  )

  if (navigateTo) {
    return (
      <NavLink
        to={navigateTo ?? ''}
        onClick={open ? onCloseHandler : noop}
        className={({ isActive }) => menuItemClassName(isActive)}
      >
        {itemContent}
      </NavLink>
    )
  }

  return <div className={menuItemClassName()}>{itemContent}</div>
}

export default SidebarMenuItem
