import { type FC, Fragment } from 'react'

import { useLanguageTranslation } from 'hooks'

import { checkRenderValue } from './utils'
import type { IPaymentsTableItem } from './types'
import styles from './PaymentsTableItem.module.scss'

const MyAcTableItem: FC<IPaymentsTableItem> = ({ item, onClick }) => {
  const { language } = useLanguageTranslation()

  const rows: string[] = [
    `#${item?.id}`,
    item?.type,
    item?.name[language],
    item?.method,
    item?.currency,
    String(item?.amount),
    item?.createdAt,
    item?.availability,
    item?.status,
  ]

  const renderRows = rows?.map(element => <Fragment key={element}>{checkRenderValue(element, item)}</Fragment>)

  return (
    <div role='button' onClick={() => onClick(item)} className={styles.wrapper}>
      {renderRows}
    </div>
  )
}

export default MyAcTableItem
