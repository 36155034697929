import { FC } from 'react'
import { Helmet } from 'react-helmet'

import { HelmetProps } from './types'

const HelmetLayout: FC<HelmetProps> = ({ children, title }) => (
  <>
    <Helmet>
      <title>{title}</title>
    </Helmet>
    {children}
  </>
)

export default HelmetLayout
