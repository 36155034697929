import { FC, useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'

import { PlusPrimaryIcon } from 'assets'
import { addQuestionScheme } from 'utils'
import { EButtonVariants, ESizes } from 'components/types'
import { Button, Input, LessonExam, Text } from 'components'

import Answer from './Answer'
import type { TAddQuestionProps } from './types'
import styles from './AddQuestion.module.scss'
import { IExamQuestion } from '../LessonExam/types'

const AddQuestion: FC<TAddQuestionProps> = ({ id }) => {
  const { register, handleSubmit } = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(addQuestionScheme),
  })

  const [textAnswers, setTextAnswers] = useState<boolean>(false)
  const [isLessonExam, setIsLessonExam] = useState<boolean>(false)
  const [questions, setQuestions] = useState<IExamQuestion>({ answers: [], order: 1, question: '' })

  const handleCheckboxChange = () => {
    setTextAnswers(!textAnswers)
  }

  const addAnswer = () => {
    const newId = questions.answers.length ? questions.answers[questions.answers.length - 1]?.id + 1 : 1

    setQuestions(prev => ({
      ...prev,
      answers: [
        ...questions.answers,
        { id: newId, isCorrect: false, answer: 'answer' + newId, answerName: 'answer' + newId },
      ],
    }))
  }

  const onSubmit = (data: any) => {
    const answersList = questions.answers.map(question => ({
      ...question,
      answer: data[`answer${question.id}`],
    }))

    setQuestions({
      answers: answersList,
      question: data.question,
      order: id,
    })

    setIsLessonExam(true)
  }

  const onEdit = () => {
    setIsLessonExam(false)
  }

  const onAnswer = (id: number) => {
    setQuestions(prev => ({
      ...prev,
      answers: prev.answers.map(e => (e.id === id ? { ...e, isCorrect: !e.isCorrect } : e)),
    }))
  }

  const onDelete = (id: number) => {
    const newArr = questions.answers.filter(answer => {
      if (answer.id === id) {
        return false
      }

      return true
    })

    setQuestions(prev => ({
      ...prev,
      answers: newArr,
    }))
  }

  const handleLessonExamDelete = () => {
    setQuestions({ answers: [], order: 1, question: '' })
  }

  const renderAnswers = questions.answers.map(({ id, answerName, isCorrect }, idx: number) => (
    <Answer
      id={id}
      key={idx}
      register={register}
      answerName={answerName}
      isCorrect={isCorrect}
      onAnswer={() => onAnswer(Number(id))}
      onDelete={() => onDelete(Number(id))}
    />
  ))

  return isLessonExam ? (
    <LessonExam
      onEdit={onEdit}
      order={questions.order}
      answers={questions.answers}
      question={questions.question}
      onDelete={handleLessonExamDelete}
    />
  ) : (
    <div className={styles.wrapper}>
      <div className={styles.wrapper__id}>
        <p>{id}</p>
      </div>

      <form className={styles.wrapper__main} onSubmit={handleSubmit(onSubmit)}>
        <Input name={'question'} placeholder='Enter question' size={ESizes.Large} register={register} />

        <div className={styles.wrapper__main__header}>
          <Text
            tagName='h2'
            text='Fill answers and select correct one(s)'
            className={styles.wrapper__main__header__title}
          />
          <div className={styles.wrapper__main__header__left}>
            <div>
              <Button
                onClick={addAnswer}
                variant={EButtonVariants.Secondary}
                size={ESizes.Medium}
                RightIcon={PlusPrimaryIcon}
              >
                Add answer
              </Button>
            </div>

            <label className={styles.wrapper__main__header__left__switch}>
              <input
                type='checkbox'
                checked={textAnswers}
                onChange={handleCheckboxChange}
                className={styles.wrapper__main__header__left__switch__input}
              />
              <span className={styles.wrapper__main__header__left__switch__slider} />
            </label>
            <Text className={styles.wrapper__main__header__left__text} tagName='span' text='Text Answer' />
          </div>
        </div>

        <div className={styles.wrapper__main__answers}>{renderAnswers}</div>

        <div className={styles.wrapper__main__btns}>
          <div>
            <Button variant={EButtonVariants.Tertiary} size={ESizes.Large}>
              Cancel
            </Button>
          </div>
          <div>
            <Button type='submit' size={ESizes.Large} variant={EButtonVariants.Primary}>
              Save Lesson
            </Button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default AddQuestion
