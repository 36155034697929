import axios, { AxiosRequestConfig } from 'axios'

import { getCookie, removeCookie, setCookie } from 'libraries/cookie'
import { COOKIE_EXPIRATION_TIME, REFRESH_COOKIE_EXPIRATION_TIME } from 'utils'

const defaultOptions = {
  baseURL: process.env.REACT_APP_BASE_URL,
}

const axiosInstance = axios.create(defaultOptions)

axiosInstance.interceptors.request.use(
  async (config: AxiosRequestConfig) => {
    const selectedLanguage = localStorage.getItem('i18nextLng') || 'en'

    const token = getCookie('token')

    config.headers = {
      Authorization: `Bearer ${token}`,
      'x-custom-lang': selectedLanguage,
    }

    return config
  },

  error => Promise.reject(error)
)

axiosInstance.interceptors.response.use(
  config => Promise.resolve(config),
  async error => {
    const refreshToken = getCookie('refreshToken')

    if (error.response.status === 401 && refreshToken) {
      try {
        const { data } = await axios.post(`${process.env.REACT_APP_BASE_URL}/auth-worker/refresh-token`, {
          refreshToken,
        })

        setCookie('worker', data?.data?.worker, COOKIE_EXPIRATION_TIME)
        setCookie('token', data?.data?.token, COOKIE_EXPIRATION_TIME)
        setCookie('refreshToken', data?.data?.refreshToken, REFRESH_COOKIE_EXPIRATION_TIME)

        axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${data?.data?.token}`

        return Promise.reject(error)
      } catch (err: any) {
        removeCookie('worker')
        removeCookie('token')
        removeCookie('refreshToken')

        location.replace('/')

        return Promise.reject(error)
      }
    }

    return Promise.reject(error)
  }
)

export default axiosInstance
