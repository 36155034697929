import type { FC } from 'react'

import { ArrowLeftIcon } from 'assets'
import { Button, Text } from 'components'
import { EButtonVariants, ESizes } from 'components/types'

import type { TEditorHeaderProps } from './types'
import styles from './EditorHeader.module.scss'

const EditorHeader: FC<TEditorHeaderProps> = ({ id, title, onClick }) => (
  <div className={styles.wrapper}>
    <div className={styles.wrapper__left}>
      <Button
        size={ESizes.Large}
        onClick={onClick}
        LeftIcon={ArrowLeftIcon}
        className={styles.wrapper__left__btn}
        variant={EButtonVariants.Tertiary}
      />

      <Text tagName='p' className={styles.wrapper__left__text} text='Topics' />
    </div>

    <div className={styles.wrapper__main}>
      <p className={styles.wrapper__main__id}>{id}</p>
      <Text tagName='p' className={styles.wrapper__main__title} text={title} />
    </div>

    <div className={styles.wrapper__empty} />
  </div>
)

export default EditorHeader
