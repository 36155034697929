import type { FC } from 'react'

import { Modal } from 'components'
import { Button, Input } from '@amatuni-crypto-academy/amatuni-crypto-academy-education'

import { IProfileEditModalProps } from './types'
import styles from './ProfileEditModal.module.scss'

const ProfileEditModal: FC<IProfileEditModalProps> = ({ onClose, open }) => (
  <Modal
    open={open}
    onClose={onClose}
    title='Edit full name'
    className={styles.wrapper}
    contentClassName={styles.wrapper__modal_content}
    titleContainerClassName={styles.wrapper__title}
  >
    <form className={styles.wrapper__container}>
      <div className={styles.wrapper__inputs}>
        <Input size='large' name='firstName' label='First Name' placeholder='Enter first name' />

        <Input size='large' name='lastName' label='Last Name' placeholder='Enter last name' />
      </div>

      <div className={styles.wrapper__buttons}>
        <Button onClick={onClose} size='large' variant='tertiary'>
          Cancel
        </Button>

        <Button size='large' variant='primary'>
          Save
        </Button>
      </div>
    </form>
  </Modal>
)

export default ProfileEditModal
