import type { TPaymentsHeaderProps } from 'pages/Payments/types'

export const PaymentsTableHeaderData: TPaymentsHeaderProps[] = [
  { id: 1, name: 'ID' },
  { id: 2, name: 'Type' },
  { id: 3, name: 'Name' },
  { id: 4, name: 'Method' },
  { id: 5, name: 'Currency', centerAligned: true },
  { id: 6, name: 'Amount', sortable: true, centerAligned: true },
  { id: 7, name: 'Created at', sortable: true, rightAligned: true },
  { id: 8, name: 'Availability', sortable: true, rightAligned: true },
  { id: 9, name: 'Status', rightAligned: true },
]
