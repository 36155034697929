import { useState, type FC } from 'react'
import classNames from 'classnames'

import { ESizes } from 'components/types'
import { Checkbox, Input, Text } from 'components'
import { CreditCardIcon, CryptoIcon } from 'assets'

import type { TFooterProps } from './types'
import styles from './Footer.module.scss'

const Footer: FC<TFooterProps> = ({ className }) => {
  const [isChecked, setChecked] = useState<boolean>(false)

  const checkHandler = () => setChecked(!isChecked)

  return (
    <footer className={classNames(styles.wrapper, className)}>
      <p className={styles.wrapper__enter}>
        <Text text='Enter price' />:
      </p>

      <div className={styles.wrapper__crypto}>
        <div className={styles.wrapper__crypto__left}>
          <div className={styles.wrapper__pay_method_title}>
            <CryptoIcon />

            <Text tagName='span' text='Crypto' />
          </div>

          <Checkbox
            labelClassName={classNames(styles.wrapper__crypto__checkbox_label, {
              [styles.wrapper__crypto__checkbox_label__active]: isChecked,
            })}
            className={classNames(styles.wrapper__crypto__checkbox, {
              [styles.wrapper__crypto__checkbox__active]: isChecked,
            })}
            label='Beneficiary address'
            name='Beneficiary address'
            onChange={checkHandler}
          />
        </div>

        <div className={styles.wrapper__input}>
          <Input size={ESizes.Large} name='crypto' placeholder='0.00 ' />
        </div>
      </div>

      <div className={styles.wrapper__credit}>
        <div className={styles.wrapper__pay_method_title}>
          <CreditCardIcon />

          <Text tagName='span' text='Credit card' />
        </div>

        <div className={styles.wrapper__input}>
          <Input size={ESizes.Large} name='credit' placeholder='0.00 $' />
        </div>
      </div>
    </footer>
  )
}

export default Footer
