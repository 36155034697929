import classNames from 'classnames'

import {
  DollarIcon,
  BinanceIcon,
  AmatikTokenIcon,
  MasterSecondaryIcon,
  FreeLessonsSecondaryIcon,
  BachelorSecondaryIcon,
  ProfessorSecondaryIcon,
  ExpertSecondaryIcon,
} from 'assets'
import { Text, Badge } from 'components'
import type { TPaymentsHistory } from 'pages/Payments/types'

import styles from './PaymentsTableItem.module.scss'

export const returnIcon = (el: string) => {
  switch (el) {
    case 'bachelor':
      return BachelorSecondaryIcon

    case 'professor':
      return ProfessorSecondaryIcon

    case 'expert':
      return ExpertSecondaryIcon

    case 'free':
      return FreeLessonsSecondaryIcon

    case 'master':
      return MasterSecondaryIcon

    default:
      return
  }
}

export const returnCurrencyIcon = (el: string) => {
  switch (el) {
    case 'usd':
      return <DollarIcon />

    case 'amt':
      return <AmatikTokenIcon />

    case 'bnb':
      return <BinanceIcon />

    default:
      return <p className={styles['wrapper__container_currency_empty']}>━</p>
  }
}

export const formatDate = (el: string): string => {
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  }

  // @TODO-_- en-US need to change dynamic value
  const formattedDateTime: string = new Date(el).toLocaleString('en-US', options)

  return formattedDateTime
}

export const checkRenderValue = (el: string, item: TPaymentsHistory) => {
  switch (el) {
    case item?.type:
      const RenderIcon = returnIcon(item?.type)

      return (
        <div className={styles['wrapper__container']}>
          <Text
            text={el}
            LeftIcon={RenderIcon}
            className={classNames(styles['wrapper__container_type'], {
              [styles['wrapper__container_type_empty']]: !RenderIcon,
            })}
          />
        </div>
      )

    case item?.currency:
      return (
        <div className={styles['wrapper__container']}>
          <div className={styles['wrapper__container_currency']}>{returnCurrencyIcon(el)}</div>
        </div>
      )

    case String(item?.amount):
      return (
        <div className={classNames(styles['wrapper__container'], styles['wrapper__container_center'])}>
          <p className={classNames(styles['wrapper__container_text'], styles['wrapper__container_uppercase'])}>
            {String((Number(el) ?? 0) / 100)} {item?.currency}
          </p>
        </div>
      )

    case item?.createdAt:
      return (
        <div className={classNames(styles['wrapper__container'], styles['wrapper__container__end'])}>
          <Text
            text={formatDate(el)}
            className={classNames(styles['wrapper__container_text'], styles['wrapper__container_white_space'])}
          />
        </div>
      )

    case item?.availability:
      return (
        <div className={classNames(styles['wrapper__container'], styles['wrapper__container__end'])}>
          <Text
            text={el}
            className={classNames(styles['wrapper__container_text'], styles['wrapper__container_white_space'])}
          />
        </div>
      )

    case item?.status:
      return (
        <div className={classNames(styles['wrapper__container'], styles['wrapper__container__end'])}>
          <Badge text={el} status={item?.status} />
        </div>
      )

    default:
      return (
        <div className={styles['wrapper__container']}>
          <Text text={el} className={styles['wrapper__container_text']} />
        </div>
      )
  }
}
