import { type FC, Fragment } from 'react'

import Header from '../Header'
import Sidebar from '../Sidebar'

import AuthLayout from '../AuthLayout'

import type { TProps } from './types'
import styles from './DashboardLayout.module.scss'

const DashboardLayout: FC<TProps> = ({ children, isAuthenticated, isPrivate }) => (
  <Fragment>
    {isAuthenticated && isPrivate ? (
      <main className={styles.wrapper}>
        <Header />

        <section className={styles.wrapper__content}>
          <Sidebar />
          {children}
        </section>
      </main>
    ) : (
      <AuthLayout>{children}</AuthLayout>
    )}
  </Fragment>
)

export default DashboardLayout
